import React from 'react'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { Text } from '@system'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  text: {
    color: theme.palette.text.white,
    textAlign: 'center',
    fontSize: '24px',
  },
  imageContainer: {
    width: '100%',
    marginBottom: '2.5rem',
  },
}))

const SimpleCard = (props) => {
  const classes = useStyles()
  const { text, image } = props.blok
  return (
    <SbEditable content={props.blok}>
      <Box className={classes.root}>
        <Box className={classes.imageContainer}>{renderBloks(image)}</Box>
        <Text className={classes.text}>{text}</Text>
      </Box>
    </SbEditable>
  )
}

export default SimpleCard
